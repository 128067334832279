import React from "react"
// import { Link } from "gatsby"

function Opportunities(props) {
  const {opportunities} = props

  // function handleClick() {
  //   if (typeof window !== "undefined") {
  //     if (window.Beacon) {
  //       window.Beacon("toggle")
  //     } else {
  //       alert("Chat helper connection timeout. Please refresh and try again.");
  //     }
  //   }
  // }

  return (
    <div className="section-opportunities">
     <div className="container">
      <h2 id="opportunitiesTitle" className="content-title">{ props.title }</h2>
      <p className="content-text">{ props.desc }</p>
      <div className="cards">
        { opportunities.map((item,i) => {
          return (
        <div className="opportunities-wrapper" key={i}>
          {/* <Link to={ item.link } className="job-holder" >
            <div className="opportunities-content-holder">
              <p className="job-title">{ item.job }</p>
            </div>
            <div className="opportunities-content-holder">
            <p className="job-link">Apply</p>
            </div>
          </Link> */}

          <a 
            href="https://forms.gle/BDgLKyPTjFFr4vwh9" 
            rel="noopener noreferrer nofollow" 
            target="_blank"
            // onClick={handleClick} 
            className="job-holder" 
          >
            <div className="opportunities-content-holder">
              <p className="job-title">{ item.job }</p>
            </div>
            <div className="opportunities-content-holder">
            <p className="job-link">Apply</p>
            </div>
          </a>
        </div>
        )
        }) }
      </div>
      
      </div>
    </div>
  )
}

export default Opportunities