import React from "react"

function Text(props) {
  const {text, title} = props

  return (
    <div className="section-text">
     <div className="container">
      <h1 className="text-title">{ title }</h1>
      { text.map((item,i) => {
         return (
           <div className="text-desc-holder" key={i}>
            <p className="text-desc">{ item.desc }</p>
           </div>
         )
       }) }
     </div>
    </div>
  )
}

export default Text