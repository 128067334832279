import React from "react"

function Values(props) {
  const {values} = props

  return (
    <div className="section-values">
      <div className="container">
        <div className="values-wrapper">
          { values.map((item,i) => {
            return (
              <div className="image-holder" key={i}>
                <img src={ item.image } className="img-fluid" alt={ item.name }/>
              </div>
            )
          }) }

          <div className="culture-holder">
            <h2 className="culture-title">{ props.title }</h2>
            <p className="culture-desc">{ props.firstDefinition }</p>
            <p className="culture-desc">{ props.secondDefinition }</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Values