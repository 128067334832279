import React from "react"

function GalleryImage(props) {
  const { images } = props
  return (
  <div className="section-gallery">
    <div className="container">
      <div className="gallery-wrapper">
        {
          images.map((item,i) => {
            return (
              <div className="gallery-image-holder" key={i}>
                {item.pics.map((subitem, j) => {
                  return (
                    <div className="image-frame" key={j}>
                      {
                        subitem.set.map((imageItem, k) => {
                          return (
                            <div className="image-frame-inner" key={k}>
                              <img src={ imageItem.img } className="img-fluid" alt={ imageItem.name }/>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })}
              </div>
            )
          })
        }
      </div>
    </div>
  </div>
  )
}

export default GalleryImage